import styled, { css } from 'styled-components';

const sharedHighlightStyle = css`
  display: inline-block;
  vertical-align: top;
`;
export const StyledTileTitle = styled.div`
  font-weight: bold;
  font-size: 40px;
  text-align: center;
`;

export const StyledTextHighlighter = styled.span`
  ${sharedHighlightStyle};
  font-size: 18px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.Violet};
`;

export const StyledSvgHighlighter = styled.span`
  ${sharedHighlightStyle};

  svg {
    height: 20px;
    width: auto;
    path {
      fill: ${({ theme }) => theme.colors.Violet};
    }
  }
`;

export const StyledTextContainer = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  padding-bottom: 8px;
`;

export const StyledTitleContainer = styled.div`
  font-size: 24px;
  line-height: 32px;
`;
