import React, { FC } from 'react';
import { TileTopProps } from '../../types';
import StatusChip from '../Chip/Chip';
import {
  StyledTileTitle,
  StyledTitleContainer,
} from '../Common/content-styles';

export const TileTop: FC<TileTopProps> = ({
  title,
  status,
  statusColors,
  statusBackgroundColors,
}: TileTopProps): React.ReactElement => (
  <StyledTileTitle>
    <StyledTitleContainer style={{ textTransform: 'capitalize' }}>
      {title}
    </StyledTitleContainer>
    <StatusChip
      status={status}
      // styles={{ display: 'inline-block', verticalAlign: 'text-bottom' }}
      statusColors={statusColors}
      statusBackgroundColors={statusBackgroundColors}
    />
  </StyledTileTitle>
);
