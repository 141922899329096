import React, { FC } from 'react';
import { TileData } from '../../api-models';
import { ReactComponent as DownArrow } from '../../assets/images/arrow-alt-down.svg';
import { ReactComponent as UpArrow } from '../../assets/images/arrow-alt-up.svg';
import { useGetService, UseGetServiceResult } from '../../hooks';
import colors from '../../styles/colors';
import { Status } from '../../types';
import {
  StyledSvgHighlighter,
  StyledTextContainer,
  StyledTextHighlighter,
} from '../Common/content-styles';
import {
  StyledTileBottomContainer,
  StyledTileContainer,
  StyledTileContentContainer,
  StyledTileTopContainer,
} from '../Common/layout-styles';
import { TileTop } from '../TileTop/TileTop';
import { StyledMessageContainer, StyledTitleContainer } from './styles';

const DailyUsage: FC = () => {
  const { loading, data, error }: UseGetServiceResult<TileData> =
    useGetService();
  return (
    <StyledTileContainer>
      {loading && <h4>Loading...</h4>}
      {error && <h4>{error.message}</h4>}
      {data && (
        <>
          <StyledTileTopContainer>
            <TileTop
              title={data.dailyUsageTile.tileTitle}
              status={data.dailyUsageTile.tileStatus}
              statusColors={{
                [Status.High]: colors.Amaranth,
                [Status.Medium]: colors.Amber,
                [Status.Low]: colors.Limeade,
              }}
              statusBackgroundColors={{
                [Status.High]: colors.White,
                [Status.Medium]: colors.Thunder,
                [Status.Low]: colors.Thunder,
              }}
            />
          </StyledTileTopContainer>
          <StyledTileContentContainer>
            <StyledTitleContainer>
              {data.dailyUsageTile.usageValue}
            </StyledTitleContainer>
            <StyledMessageContainer>
              {data.dailyUsageTile.usageUnit.replace(' ', '\n')}
            </StyledMessageContainer>
          </StyledTileContentContainer>

          <StyledTileBottomContainer>
            <StyledTextContainer>
              <StyledTextHighlighter>{`${data.dailyUsageTile.diffToTarget}%`}</StyledTextHighlighter>
              &nbsp;
              {`${data.dailyUsageTile.directionToTarget} target for this time of year`}
            </StyledTextContainer>
            <StyledTextContainer>
              <StyledSvgHighlighter>
                {data.dailyUsageTile.usageTrend.toLowerCase() === 'up' ? (
                  <UpArrow />
                ) : (
                  <DownArrow />
                )}
              </StyledSvgHighlighter>
              &nbsp; compared to 7 days ago
            </StyledTextContainer>
          </StyledTileBottomContainer>
        </>
      )}
    </StyledTileContainer>
  );
};

export default DailyUsage;
