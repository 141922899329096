import { useEffect, useState } from 'react';
import { TileData } from '../api-models';
import { get } from '../services';

export interface UseGetServiceResult<DataType> {
  loading: boolean;
  data: DataType | undefined;
  error: Error | undefined;
}

export function useGetService(): UseGetServiceResult<TileData> {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<TileData>();
  const [error, setError] = useState<Error>();

  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const response = await get();
        setData(response.data);
      } catch (e) {
        setError(<Error>e);
      }
      setLoading(false);
    }

    if (!data && !error) {
      getData();
    }
  }, [data, error]);

  return { loading, data, error };
}
