const colors = {
  White: '#FFFFFF',
  RiverBed: '#43575a',
  Eucalyptus: '#28A062',
  Limeade: '#80BC00',
  Amaranth: '#E53E51',
  Amber: '#F7BE00',
  Gray: '#E1E5E5',
  Thunder: '#231F20',
  MediumGray: '#929e9f',
  DarkGray: '#43575A',
  MediumBlue: '#CCEEF7',
  Blue: '#0082CA',
  Violet: '#69488E',
  Orange: '#F88D2B',
  Green: '#477A20',
  Red: '#B4432F',
  Yellow: '#F9C83A',
  Black: '#000000',
};

export default colors;
