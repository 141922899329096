import { createGlobalStyle, DefaultTheme } from 'styled-components';

const GlobalStyle = createGlobalStyle<{ theme: DefaultTheme }>`  
  html, body, #root {
    height: 100%;
  }
  body {
    font-family: ${({ theme }) => theme.fonts.default};
    font-style: normal;
    margin: 0;
    background-color: ${({ theme }) => theme.colors.White};
    color: ${({ theme }) => theme.colors.RiverBed};
  }
`;

export default GlobalStyle;
