import styled, { css } from 'styled-components';

// const sharedPaddingStyle = css`
//   padding: 24px 0;
// `;

const sharedFlexStyle = css`
  display: flex;
  flex-direction: column;
`;

const sharedCenterAlginStyle = css`
  align-items: center;
  justify-content: center;
`;

export const StyledTileContainer = styled.div`
  ${sharedFlexStyle};
  box-sizing: border-box;
  height: 100%;
  text-align: center;
  padding: 24px;
`;

export const StyledTileTopContainer = styled.div`
  ${sharedFlexStyle};
  ${sharedCenterAlginStyle};
  flex: 2;
`;

export const StyledTileContentContainer = styled.div`
  ${sharedFlexStyle};
  ${sharedCenterAlginStyle};
  flex: 5;
`;

export const StyledUnpaddedTileContentContainer = styled(
  StyledTileContentContainer
)`
  margin: 0 -24px;
`;

export const StyledTileBottomContainer = styled.div`
  ${sharedFlexStyle};
  ${sharedCenterAlginStyle};
  flex: 2;
`;
