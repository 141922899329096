import {
  CategoryAxis,
  Column,
  ColumnSeries,
  LabelBullet,
  ValueAxis,
  ValueAxisDataItem,
  XYChart,
} from '@amcharts/amcharts4/charts';
import { color, create, percent } from '@amcharts/amcharts4/core';
import { WaterSource, WaterSourcesTile } from '../api-models';
import colors from '../styles/colors';

const MAX_PERCENTAGE_VALUE = 150;
const AVERAGE_PERCENTAGE_VALUE = 100;
const MAX_VALUE = MAX_PERCENTAGE_VALUE / 100;

export function createRanges(
  sources: WaterSource[],
  valueAxis: ValueAxis
): void {
  sources.forEach((source: WaterSource) => {
    if (source.sourceValue !== null) {
      const range: ValueAxisDataItem = valueAxis.axisRanges.create();
      range.value = AVERAGE_PERCENTAGE_VALUE / 100;
      range.grid.disabled = true;
      range.tick.disabled = false;
      range.tick.above = true;
      range.tick.stroke = color(colors.Orange);
      range.tick.strokeWidth = 2;
      range.tick.strokeOpacity = 1;
      range.tick.strokeDasharray = '8,5';
    } else {
      valueAxis.axisRanges.create();
    }
  });
}
export function updateRanges(sources: WaterSource[], chart: XYChart): void {
  const { columns } = chart.series.getIndex(0) as ColumnSeries;
  const valueAxis = chart.yAxes.getIndex(0) as ValueAxis;
  sources.forEach((source: WaterSource, index: number) => {
    if (source.sourceValue !== null) {
      const column: Column = columns.getIndex(index) as Column;
      const { contentWidth, realX } = column;
      const range: ValueAxisDataItem = valueAxis.axisRanges.getIndex(
        index
      ) as ValueAxisDataItem;

      range.tick.length = -contentWidth;
      range.tick.paddingLeft = realX;
    }
  });
}

export function createBarChart(
  tileData: WaterSourcesTile,
  id: string
): XYChart {
  const chart = create(id, XYChart);

  chart.numberFormatter.numberFormat = '##%';
  chart.fontWeight = '500';
  chart.fontSize = 18;

  chart.data = tileData.sources.map((source: WaterSource) => ({
    sourceName: source.sourceName,
    sourceValue: source.sourceValue === null ? null : source.sourceValue / 100,
    refValue: MAX_VALUE,
    backgroundColor:
      source.sourceValue === null ? colors.Gray : colors.MediumBlue,
    sourceMessage:
      source.sourceMessage === null
        ? null
        : source.sourceMessage.replace(/[ ]/g, '\n'),
  }));

  const categoryAxis = chart.xAxes.push(new CategoryAxis());
  categoryAxis.dataFields.category = 'sourceName';
  categoryAxis.renderer.opposite = true;
  categoryAxis.renderer.minGridDistance = 0;
  categoryAxis.renderer.grid.template.disabled = true;
  // categoryAxis.renderer.grid.template.location = 0;
  // categoryAxis.renderer.labels.template.disabled = true;
  // categoryAxis.fontSize = 16;

  const valueAxis = chart.yAxes.push(new ValueAxis());
  valueAxis.min = 0;
  valueAxis.max = MAX_VALUE;
  valueAxis.strictMinMax = true;
  valueAxis.renderer.grid.template.disabled = true;
  valueAxis.renderer.labels.template.disabled = true;

  const columnSeries = ['refValue', 'sourceValue'].map((value: string) => {
    const series = chart.series.push(new ColumnSeries());
    series.dataFields.categoryX = 'sourceName';
    series.dataFields.valueY = value;
    series.clustered = false;
    series.columns.template.width = percent(90);
    if (value === 'sourceValue') {
      series.columns.template.fill = color(colors.Blue);
      series.columns.template.stroke = color(colors.Blue);
    } else {
      // get color from data
      series.columns.template.propertyFields.fill = 'backgroundColor';
      series.columns.template.propertyFields.stroke = 'backgroundColor';
      series.columns.template.column.cornerRadiusTopLeft = 5;
      series.columns.template.column.cornerRadiusTopRight = 5;
    }

    return series;
  });

  const refBullet = columnSeries[0].bullets.push(new LabelBullet());
  refBullet.label.dy = MAX_PERCENTAGE_VALUE / 2;
  refBullet.label.text = '{sourceMessage}';
  refBullet.label.fill = color(colors.MediumGray);
  refBullet.fontSize = 16;
  refBullet.fontWeight = '700';
  refBullet.label.textAlign = 'middle';

  const sourceBullet = columnSeries[1].bullets.push(new LabelBullet());
  sourceBullet.label.dy = -15;
  sourceBullet.label.text = '{sourceValue}';
  sourceBullet.label.fill = color(colors.Violet);
  // sourceBullet.fontSize = 16;
  refBullet.fontWeight = '700';

  createRanges(tileData.sources, valueAxis);

  return chart;
}
