import { DefaultTheme } from 'styled-components';
import colors from './colors';

const theme: DefaultTheme = {
  colors,
  fonts: {
    default: '"Helvetica Neue", "Helvetica", "Arial"',
  },
};

export default theme;
