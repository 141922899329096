export enum Status {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export interface StatusColorProps {
  statusColors?: StatusColors;
  statusBackgroundColors?: StatusColors;
}

export interface TileTopProps extends StatusColorProps {
  title: string;
  status: Status;
}

export interface ChipProps {
  status: Status;
  styles?: Record<string, string>;
}

export interface StatusChipProps extends ChipProps, StatusColorProps {
  innerStyles?: Record<string, string>;
}

export interface StatusColors {
  [Status.High]: string;
  [Status.Medium]: string;
  [Status.Low]: string;
}
