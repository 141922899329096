import { ClockHand, GaugeChart } from '@amcharts/amcharts4/charts';
import { Animation, ease } from '@amcharts/amcharts4/core';
import React, { FC, MutableRefObject, useLayoutEffect, useRef } from 'react';
import { TileData } from '../../api-models';
import { useGetService, UseGetServiceResult } from '../../hooks';
import colors from '../../styles/colors';
import { Status } from '../../types';
import { createChart } from '../../utils/gauge-chart';
import {
  StyledTextContainer,
  StyledTextHighlighter,
} from '../Common/content-styles';
import {
  StyledTileBottomContainer,
  StyledTileContainer,
  StyledTileContentContainer,
  StyledTileTopContainer,
} from '../Common/layout-styles';
import { TileTop } from '../TileTop/TileTop';

const ProductionLevel: FC = () => {
  const { loading, data, error }: UseGetServiceResult<TileData> =
    useGetService();

  const chartRef: MutableRefObject<GaugeChart | null> =
    useRef<GaugeChart | null>(null);

  const rangeColors = [colors.Green, colors.Yellow, colors.Red];

  useLayoutEffect(() => {
    if (data) {
      const rangeNumbers = [
        0,
        data.productionLevelTile.lowLimit,
        data.productionLevelTile.midLimit,
        100,
      ];
      const chart = createChart('chart-container', rangeColors, rangeNumbers);
      const hand = chart.hands.getIndex(0) as ClockHand;
      chart.events.on('ready', () => {
        new Animation(
          hand,
          {
            property: 'value',
            to: data.productionLevelTile.productionLevel / 100,
          },
          1000,
          ease.cubicOut
        ).start();
      });
      chartRef.current = chart;
    }
    return () => {
      chartRef.current?.dispose();
    };
  });
  return (
    <StyledTileContainer>
      {loading && <h4>Loading...</h4>}
      {error && <h4>{error.message}</h4>}
      {data && (
        <>
          <StyledTileTopContainer>
            <TileTop
              title={data.productionLevelTile.tileTitle}
              status={data.productionLevelTile.tileStatus}
              statusColors={{
                [Status.High]: colors.Amaranth,
                [Status.Medium]: colors.Amber,
                [Status.Low]: colors.Limeade,
              }}
              statusBackgroundColors={{
                [Status.High]: colors.White,
                [Status.Medium]: colors.Thunder,
                [Status.Low]: colors.Thunder,
              }}
            />
          </StyledTileTopContainer>
          <StyledTileContentContainer>
            <div
              id="chart-container"
              style={{
                height: 224,
              }}
            />
          </StyledTileContentContainer>
          <StyledTileBottomContainer>
            <StyledTextContainer>
              <StyledTextHighlighter>{`${data.productionLevelTile.diffToPrevious}%`}</StyledTextHighlighter>
              &nbsp;compared to 7 days ago
            </StyledTextContainer>
            <StyledTextContainer>&nbsp;</StyledTextContainer>
          </StyledTileBottomContainer>
        </>
      )}
    </StyledTileContainer>
  );
};

export default ProductionLevel;
