import React, { FC } from 'react';
import { Link } from 'react-router-dom';

const Home: FC = () => {
  return (
    <div>
      <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/DailyUsage">Daily Usage</Link>
          </li>
          <li>
            <Link to="/ProductionLevel">Production Level</Link>
          </li>
          <li>
            <Link to="/WaterSources">Water Sources</Link>
          </li>
          <li>
            <a href="/graphs/comparison.html">Water Use Graph</a>
          </li>
          <li>
            <a href="/graphs/trend.html">Water Demand Drivers Graph</a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Home;
