import React, { FC } from 'react';
import { TileData } from '../../api-models';
import { useGetService, UseGetServiceResult } from '../../hooks';

const UpdatedDate: FC = () => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  const { loading, data, error }: UseGetServiceResult<TileData> =
    useGetService();

  return (
    <>
      {loading && <h4>Loading...</h4>}
      {error && <h4>{error.message}</h4>}
      {data && (
        <div
          style={{
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '26px',
          }}
        >
          Information last updated on{' '}
          {new Date(data.dateLastUpdated)
            .toLocaleDateString('en-NZ', options)
            .replace(/,/g, '')}
        </div>
      )}
    </>
  );
};

export default UpdatedDate;
