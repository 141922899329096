import {
  AxisRendererCircular,
  ClockHand,
  GaugeChart,
  ValueAxis,
} from '@amcharts/amcharts4/charts';
import { color, create, percent } from '@amcharts/amcharts4/core';
import colors from '../styles/colors';

export function createChart(
  containerId: string,
  rangeColors: string[],
  rangeNumbers: number[]
): GaugeChart {
  const chart = create(containerId, GaugeChart);

  chart.fontSize = 18;
  chart.fontWeight = '500';
  chart.innerRadius = -10;
  chart.startAngle = -225;
  chart.endAngle = 45;
  chart.numberFormatter.numberFormat = '##%';

  const outterAxis = chart.xAxes.push(new ValueAxis<AxisRendererCircular>());
  outterAxis.min = 0;
  outterAxis.max = 1;
  outterAxis.strictMinMax = true;

  outterAxis.renderer.grid.template.strokeWidth = 0;
  outterAxis.renderer.labels.template.disabled = true;
  outterAxis.renderer.line.strokeOpacity = 1;
  outterAxis.renderer.line.strokeWidth = 4;

  const innerAxis = chart.xAxes.push(new ValueAxis<AxisRendererCircular>());
  innerAxis.min = 0;
  innerAxis.max = 1;
  innerAxis.strictMinMax = true;

  innerAxis.renderer.inside = true;
  innerAxis.renderer.grid.template.strokeWidth = 0;
  innerAxis.renderer.labels.template.radius = -40;
  innerAxis.renderer.labels.template.disabled = true;
  innerAxis.renderer.radius = percent(90);

  // eslint-disable-next-line no-restricted-syntax
  for (const rangeColor of [...rangeColors, null]) {
    const range = innerAxis.axisRanges.create();
    if (rangeColor) {
      const index = rangeColors.indexOf(rangeColor);
      range.value = rangeNumbers[index] / 100;
      range.endValue = rangeNumbers[index + 1] / 100;
      range.axisFill.fillOpacity = 1;
      range.axisFill.fill = color(rangeColor);
      range.axisFill.zIndex = -1;
      if (rangeNumbers[index] === 0) {
        range.label.text = `${Math.floor(rangeNumbers[index])}%`;
      }
    } else {
      range.value = 1;
      range.label.text = '100%';
      range.label.dx = -10;
    }
  }

  // fixed axis labels
  // eslint-disable-next-line no-restricted-syntax
  for (const labelValue of [1 / 3, 2 / 3]) {
    const range = innerAxis.axisRanges.create();
    range.value = labelValue;
    range.label.text = `${Math.floor(labelValue * 100)}%`;
  }

  const hand = chart.hands.push(new ClockHand());
  hand.radius = percent(60);
  hand.fill = color(colors.DarkGray);
  hand.stroke = color(colors.DarkGray);
  hand.pin.radius = 7;
  hand.startWidth = 8;
  hand.endWidth = 2;
  hand.value = 0;

  return chart;
}
