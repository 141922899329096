import { XYChart } from '@amcharts/amcharts4/charts';
import { useTheme } from '@amcharts/amcharts4/core';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';
import React, { FC, MutableRefObject, useLayoutEffect, useRef } from 'react';
import { TileData } from '../../api-models';
import { useGetService, UseGetServiceResult } from '../../hooks';
import colors from '../../styles/colors';
import { Status } from '../../types';
import { createBarChart, updateRanges } from '../../utils/bar-chart';
import { StyledTextContainer } from '../Common/content-styles';
import {
  StyledTileBottomContainer,
  StyledTileContainer,
  StyledTileTopContainer,
  StyledUnpaddedTileContentContainer,
} from '../Common/layout-styles';
import { TileTop } from '../TileTop/TileTop';
import { StyledDottedLine } from './styles';

const WaterSources: FC = () => {
  useTheme(am4themesAnimated);
  const { loading, data, error }: UseGetServiceResult<TileData> =
    useGetService();

  const chartRef: MutableRefObject<XYChart | null> = useRef<XYChart | null>(
    null
  );

  useLayoutEffect(() => {
    if (data) {
      const chart = createBarChart(data.waterSourcesTile, 'chartdiv');
      chart.events.on('datavalidated', () => {
        chart.events.on('sizechanged', () => {
          chart.invalidateData();
        });

        // try catch was added to handle the situation where the contentWidth of the bar chart is not available at the time when updateRanges is invoked
        try {
          updateRanges(data.waterSourcesTile.sources, chart);
        } catch (e) {
          chart.invalidateData();
        }
      });

      chartRef.current = chart;
    }
    return () => {
      chartRef.current?.dispose();
    };
  });

  return (
    <StyledTileContainer>
      {loading && <h4>Loading...</h4>}
      {error && <h4>{error.message}</h4>}
      {data && (
        <>
          <StyledTileTopContainer>
            <TileTop
              title={data.waterSourcesTile.tileTitle}
              status={data.waterSourcesTile.tileStatus}
              statusColors={{
                [Status.High]: colors.Limeade,
                [Status.Medium]: colors.Amber,
                [Status.Low]: colors.Amaranth,
              }}
              statusBackgroundColors={{
                [Status.High]: colors.Thunder,
                [Status.Medium]: colors.Thunder,
                [Status.Low]: colors.White,
              }}
            />
          </StyledTileTopContainer>
          <StyledUnpaddedTileContentContainer>
            <div
              id="chartdiv"
              style={{
                height: 220,
                width: '100%',
                marginLeft: '-15px',
              }}
            />
          </StyledUnpaddedTileContentContainer>
          <StyledTileBottomContainer>
            <StyledTextContainer>
              <StyledDottedLine />
              &nbsp; 10 year stream-flow average
            </StyledTextContainer>
            <StyledTextContainer>&nbsp;</StyledTextContainer>
          </StyledTileBottomContainer>
        </>
      )}
    </StyledTileContainer>
  );
};

export default WaterSources;
