import { addLicense } from '@amcharts/amcharts4/core';
import React, { FC } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import './App.css';
import DailyUsage from './components/DailyUsage/DailyUsage';
import Home from './components/Home/Home';
import ProductionLevel from './components/ProductionLevel/ProductionLevel';
import UpdatedDate from './components/UpdatedDate/UpdatedDate';
import WaterSources from './components/WaterSources/WaterSources';
import GlobalStyles from './styles/global';
import theme from './styles/theme';

addLicense('CH291746709');

const App: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router>
        <Switch>
          <Route path="/DailyUsage">
            <DailyUsage />
          </Route>
          <Route path="/WaterSources">
            <WaterSources />
          </Route>
          <Route path="/ProductionLevel">
            <ProductionLevel />
          </Route>
          <Route path="/UpdatedDate">
            <UpdatedDate />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;
