import { Chip as MuiChip } from '@material-ui/core';
import styled from 'styled-components';
import { Status, StatusChipProps } from '../../types';

export const StyledMuiChip = styled(MuiChip).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => defaultValidatorFn(prop),
})<StatusChipProps>`
  min-width: 80px;
  ${({ styles }) => {
    let styleString = ``;
    if (styles) {
      Object.keys(styles).forEach((attr) => {
        styleString += `${attr}:${styles[attr]};`;
      });
    }
    return styleString;
  }}

  &.MuiChip-root {
    height: 42px;
    padding: 0 22px;
    border-radius: 5px;
    background-color: ${({ status, statusColors, theme }) => {
      switch (status) {
        case Status.Low:
          return statusColors ? statusColors[Status.Low] : theme.colors.Limeade;
        case Status.High:
          return statusColors
            ? statusColors[Status.High]
            : theme.colors.Amaranth;
        case Status.Medium:
          return statusColors
            ? statusColors[Status.Medium]
            : theme.colors.Amber;
        default:
          return theme.colors.Gray;
      }
    }};
  }
  & .MuiChip-label {
    padding: 4px 0 0 0;
    display: inline-block;
    font-family: ${({ theme }) => theme.fonts.default};
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;

    color: ${({ status, statusBackgroundColors, theme }) => {
      switch (status) {
        case Status.Low:
          return statusBackgroundColors
            ? statusBackgroundColors[Status.Low]
            : theme.colors.Thunder;
        case Status.High:
          return statusBackgroundColors
            ? statusBackgroundColors[Status.High]
            : theme.colors.White;
        case Status.Medium:
          return statusBackgroundColors
            ? statusBackgroundColors[Status.Medium]
            : theme.colors.Thunder;
        default:
          return theme.colors.Gray;
      }
    }};
  }
`;
