/* eslint-disable react/prop-types */
import React, { FC } from 'react';
import { StatusChipProps } from '../../types';
import { StyledMuiChip } from './styles';

// eslint-disable-next-line react/prop-types
const StatusChip: FC<StatusChipProps> = ({
  status,
  styles,
  statusColors,
  statusBackgroundColors,
  innerStyles,
}: StatusChipProps) => {
  return (
    <div style={{ marginTop: '16px', ...styles }}>
      <StyledMuiChip
        status={status}
        label={status.toUpperCase()}
        style={innerStyles}
        statusColors={statusColors}
        statusBackgroundColors={statusBackgroundColors}
      />
    </div>
  );
};

export default StatusChip;
